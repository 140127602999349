import React from 'react';
import ForgotPassword from '../../Components/organisms/ForgotPassword/ForgotPassword';

const ForgotPasswordScreen = () => {
  return (
    <div>
        <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordScreen